import 'whatwg-fetch';
import omit from 'framework/helpers/omit';
import { decrypt, encrypt } from 'framework/helpers/encryptedStorage';
import { v4 as uuidv4 } from 'uuid';
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

/*
 * Wraps whatwg-fetch to add some functionality to fetch that we need for our api calls.
 * Adds request timeouts and call retrying. Used exactly like regular fetch, with the only
 * difference being an additional two arguments available in the options: retries and timeout.
 *
 * Example usage:
 *
 * import fetchRetry from 'framework/api/fetchRetry';
 *
 * fetchRetry('/words/test.json', { retries: 1, timeout: 10000 })
 *  .then(res => res.json()).then(json => console.log(`this is the response json: ${json}`))
 *  .catch(err => console.log(`this is whatever message the promise rejected on: ${err}`));
 *
 * In most cases you should not have to pass the retry or timeout options since we have them
 * set to the ideal values by default
 *
 * The fetchRetry timeout/retry flow is as follows:
 *
 * 1. A request is fired. After 15 seconds it will time out (15 seconds).
 * 2. We will wait 5 seconds, and then fire another request, which after 15 seconds will also time out (35 seconds).
 * 3. We will then wait 10 seconds, and fire a third request, which after 15 seconds will also time out (60 seconds).
 * 4. When that last request times out, we'll then drop down and try and diagnose the problem (1-2 seconds).
 * 5. If all the checks pass (favicon, heartbeat) then we will report back to the user that an actual timeout has occurred.
 * 6. If one of the check fails, we'll report back to the user something like "Your internet is down..." or whatever.
 *
 * We will also go through the above sequence (steps 2-6 above) in the event that any request returns a 400 status code.
 * All other status codes that aren't 200 or 400 will not get retried, but will immediately report back to the user that there is an issue.
 *
 * @param {String} url - The endpoint for the request
 * @param {Object} options - the standard fetch options arg with the addition of:
 *  1. {Number} retries - the number of times to retry the request, DEFAULTS TO 2.
 *  2. {Number} timeout - the amount of time in ms for the request to timeout. DEFAULTS TO 15000ms.
 *
 *  @return {Promise}
 */

export default (url, options = {}) => {
  const { retries = 2, timeout = 15000, body } = options;
  let fetchOptions;

  return new Promise((resolve, reject) => {
    const fetchRetry = retriesRemaining => {
      let didTimeout = false,
        timeoutId;
      const delayTime = 10000 / retriesRemaining; // Delays 5000ms(5s), then 10000ms(10s)

      if (timeout !== 0) {
        timeoutId = setTimeout(() => {
          didTimeout = true;

          if (retriesRemaining > 0) {
            return delay(delayTime).then(() =>
              fetchRetry(retriesRemaining - 1)
            );
          } else {
            return _checkForNetworkConnectivity(
              reject,
              'Your request timed out.'
            );
          }
        }, timeout);
      }

      if (
        body &&
        process.env.JSON_DIGEST_SALT //eslint-disable-line
      ) {
        fetchOptions = options && omit(options, ['retries', 'timeout']);
        fetchOptions.body = encryptIfMutation(body);
      } else {
        fetchOptions = options && omit(options, ['retries', 'timeout']);
      }

      // let graphqlQueryOrMutation;

      // if (fetchOptions.method === 'POST' && fetchOptions.body) {
      //   const fetchBody = JSON.parse(fetchOptions.body);
      //   graphqlQueryOrMutation = fetchBody.operationName;
      // }

      return fetch(url, fetchOptions)
        .then(res => {
          const {
            headers,
            ok,
            redirected,
            status,
            statusText,
            type,
            url,
            useFinalUrl
          } = res;
          const responseReadOnlyProperties = {
            headers,
            ok,
            redirected,
            status,
            statusText,
            type,
            url,
            useFinalUrl
          };

          switch (true) {
            case /400/.test(status): {
              if (retriesRemaining > 0) {
                return delay(delayTime).then(() => {
                  return fetchRetry(retriesRemaining - 1);
                });
              } else {
                return _checkForNetworkConnectivity(
                  reject,
                  `You've made a bad request.`
                );
              }
            }
            case /401/.test(status):
              if (timeoutId > 0) clearTimeout(timeoutId);
              return window.location.assign(`/login?alert=unauthorized`);
            case /403/.test(status):
              return _handleRejectWithError('That is forbidden.', reject);
            case /404/.test(status):
              return _handleRejectWithError(
                'The requested URL could not be found.',
                reject,
                timeoutId
              );
            case /422/.test(status):
              return _handleRejectWithError(
                `You've sent some bad data.`,
                reject,
                timeoutId
              );
            case /4[0-9][0-9]/.test(status):
              return _handleRejectWithError(
                `Your action could not be performed.`,
                reject,
                timeoutId
              );
            case /5[0-9][0-9]/.test(status):
              return _handleRejectWithError(
                `We seem to be experiencing a problem.`,
                reject,
                timeoutId
              );
            case !/2[0-9][0-9]/.test(status):
              return _handleRejectWithError(
                `An error has occurred.`,
                reject,
                timeoutId
              );
            case /204/.test(status):
              clearTimeout(timeoutId);
              return resolve(res);
            default:
              return resolve(
                res
                  .json()
                  .then(_clearTimeoutIfSuccess(timeoutId))
                  .then(decryptIfNeccessary)
                  .then(redirectIfNecessary)
                  .then(
                    finalRes =>
                      new Response(
                        JSON.stringify(finalRes),
                        responseReadOnlyProperties
                      )
                  )
              );
          }
        })
        .catch(err => {
          if (didTimeout) {
            return;
          } else {
            _checkForNetworkConnectivity(reject, err, timeoutId);
            // _handleRejectWithError(err, reject, timeoutId);
          }
        });
    };
    fetchRetry(retries);
  });
};

function _clearTimeoutIfSuccess(timeoutId) {
  clearTimeout(timeoutId);
  return res => res;
}

function _handleRejectWithError(error, reject, timeoutId, status) {
  timeoutId > 0 && clearTimeout(timeoutId);
  return reject({ message: error, ...(status && { status }) });
}

/* Handles the /favicon and /heartbeat calls to verify network connectivity or issues with our app.
 * First, we check if the favicon can be reached. If it cannot, we reject alerting the user of possible
 * internet connection issues. If the favicon can be reached we proceed to call the /heartbeat endpoint
 * for a health check. If the health check comes back with a 200 response status, we reject and alert that
 * the request likely timed out to try again or contact support. If the health check fails we reject and
 * alert that we're experiencing a problem and the user should contact support.
 *
 * @param {Function} reject - the reject from the wrapping promise
 * @param {String} originalError - pass through error message to be rejected on. Mainly used for the timeout message.
 *
 * @return {Promise} resolved or rejected promise
 */
function _checkForNetworkConnectivity(reject, originalError, timeoutId) {
  return fetchWithTimeout(`/favicon.ico?t=${Date.now()}`)
    .then(res => {
      if (res.status === 200) {
        return fetchWithTimeout('/heartbeat')
          .then(healthResponse => {
            if (healthResponse.status === 200) {
              // Network issues ruled out. Actual timeout.
              return _handleRejectWithError(originalError, reject, timeoutId);
            } else {
              // Heartbeat failed with a weird status code.
              throw 'bad heartbeat status';
            }
          })
          .catch(() => {
            return _handleRejectWithError(
              'We seem to be experiencing a problem. Please contact support@membean.com for help.',
              reject,
              timeoutId
            );
          });
      } else {
        // Favicon failed with a weird status code.
        throw 'bad favicon status';
      }
    })
    .catch(() => {
      return _handleRejectWithError(
        'Your internet connection appears to be down. Please contact your network administrator.',
        reject,
        timeoutId
      );
    });
}

function redirectIfNecessary(jsonBody) {
  // redirect if the response type is 'redirect' and there is a redirect_url
  const jsonResponse = jsonBody.payload || jsonBody;
  if (
    jsonResponse &&
    jsonResponse.redirect_url &&
    jsonResponse.type === 'redirect'
  ) {
    window.location.assign(jsonResponse.redirect_url);
  }
  return jsonBody;
}

function decryptIfNeccessary(response) {
  // if the res JSON has a data AND token key then it is in our encrypted response format and we need
  // to decrypt and hold on to the token for CSRF headers
  const { token, payload, cipher } = response;

  if (cipher) {
    const decryptedPayload = decrypt(cipher, token);
    // if the response is encrypted there will be a cypher in the json
    return {
      ...decryptedPayload,
      data: { ...decryptedPayload.data, token },
      error: decryptedPayload.errors
    };
  } else if (payload && (payload.data || payload.errors)) {
    // if the payload.data keys exist this is a graphql response and we should append the token to the data and return
    return {
      ...payload,
      data: { ...payload.data, token },
      error: payload.errors
    };
  } else {
    // this probaly means this is call from the calibration or trainer/word page
    return response;
  }
}

function encryptIfMutation(body) {
  const generatedEncryptionToken = uuidv4();
  const encryptedRequest = encrypt(body, generatedEncryptionToken);
  return JSON.stringify({
    token: generatedEncryptionToken,
    cipher: encryptedRequest
  });
}

function fetchWithTimeout(url, options = {}) {
  return new Promise((resolve, reject) => {
    const { timeout = 5000 } = options;
    let timeoutId;

    timeoutId = setTimeout(() => {
      reject('Your request has timed out.');
    }, timeout);

    fetch(url, omit(options, ['timeout']))
      .then(res => {
        clearTimeout(timeoutId);
        resolve(res);
      })
      .catch(err => {
        clearTimeout(timeoutId);
        reject(err);
      });
  });
}
