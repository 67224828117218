/**
 * UseInterval hook helps us to execute a function at an interval of provided time..
 * Clears the interval when the component is unmounted.
 *
 * Example usage:
 *
 * import useInterval from 'framework/helpers/useInterval';
 *
 * useInterval(()=>{
 *  /// write a function to execute at an interval.
 * },3000)
 *
 */

import { useEffect, useRef } from 'react';

const useInterval = (callback, delay = 0) => {
  const savedCallback = useRef();
  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const intervalId = setInterval(tick, delay);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [callback, delay]);
};

export default useInterval;
