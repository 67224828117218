import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'framework/components/Icon';

export default class Alert extends React.Component {
  constructor() {
    super();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  assignCSS() {
    const { alert } = this.props;
    return ['type', 'position'].reduce(
      (css, key) =>
        alert[key] ? css.concat(' alert-', alert[key]) : css.concat(''),
      `alert${alert.dismissable ? ' alert-dismissable' : ''}`
    );
  }

  componentDidMount() {
    // Set focus on the alert if positioned top or bottom
    this.shouldFocus() && this.alertBox.focus();
  }

  handleButtonClick(button) {
    if (button.url) {
      if (button.target) {
        window.open(button.url, button.target);
      } else {
        window.location = button.url;
      }
    } else if (button.onClick) {
      button.onClick();
    }
  }

  handleKeyDown(event) {
    const closeKey = event.key === 'Escape' || event.key === 'x';
    const dismissable = this.props.alert.dismissable;
    if (dismissable && closeKey) this.props.dismissCallback();
  }

  shouldFocus() {
    const { dismissable, position } = this.props.alert;
    const hasPosition = position ? position.match(/bottom|top/) != null : false;
    return dismissable && hasPosition;
  }

  render() {
    const { id, alert, visible = true } = this.props;
    let alertDescriptionHtml;
    const { description = '' } = alert;
    const renderInnerHtml = /<\/?[a-z][\s\S]*>/i.test(alert.description);
    if (renderInnerHtml) {
      alertDescriptionHtml = (
        <span
          data-testid="alert-description"
          id="alert-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></span>
      );
    } else {
      if (typeof alert.description === 'object') {
        alertDescriptionHtml = (
          <span data-testid="alert-description" id="alert-description">
            {description.message}
          </span>
        );
      } else {
        alertDescriptionHtml = (
          <span data-testid="alert-description" id="alert-description">
            {description}
          </span>
        );
      }
    }
    return (
      alert &&
      visible && (
        <div
          className={`${this.assignCSS()}`}
          onKeyDown={this.handleKeyDown}
          ref={el => {
            this.alertBox = el;
          }}
          role="status"
          tabIndex="-1"
          id={id}
        >
          <div className="container">
            <div className="row">
              <div className="alert-text">
                <strong className="alert-title">{alert.title}</strong>
                {alertDescriptionHtml}
              </div>

              {(alert.button || alert.dismissable) && (
                <div className="alert-actions">
                  {alert.button && (
                    <button
                      aria-label={alert.button.text}
                      className={`btn btn-small btn-icon-right btn-alert-action ${alert.button.className}`}
                      onClick={() => {
                        this.handleButtonClick(alert.button);
                      }}
                      tabIndex="0"
                    >
                      {alert.button.text}
                      {alert.button.icon ? (
                        <Icon name={alert.button.icon} />
                      ) : null}
                    </button>
                  )}
                  {alert.dismissable && (
                    <button
                      aria-label="Close alert"
                      className="alert-close-btn"
                      onClick={() => {
                        this.props.dismissCallback(alert);
                      }}
                    >
                      <Icon name="close" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

Alert.propTypes = {
  alert: PropTypes.shape({
    button: PropTypes.shape({
      className: PropTypes.string,
      icon: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
      onClick: PropTypes.func
    }),
    description: PropTypes.oneOfType([
      PropTypes.shape({
        message: PropTypes.node
      }),
      PropTypes.string
    ]),
    dismissable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    position: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  dismissCallback: PropTypes.func,
  visible: PropTypes.bool
};
