import buildPostHeaders from 'framework/helpers/buildPostHeaders';
import { REVISION_ID } from 'framework/constants';

/* -- TRAINER REQUESTS -- */
const basePostRequestOptions = {
  method: 'POST',
  retries: 2,
  credentials: 'include'
};

export const wordJson = wordform => ({
  endpoint: `/words/${wordform}/data.json`
});

export const questionJson = (wordform, questionType) => ({
  endpoint: `/questions/${wordform}/${questionType}/data.json`
});

export const trainingJson = {
  endpoint: '/training/process.json',
  options: {
    ...basePostRequestOptions,
    headers: buildPostHeaders('token'),
    body: JSON.stringify({ training_gateway: { type: 'hello' } })
  }
};

export const rootData = root => ({
  endpoint: root.url,
  rootId: root.id,
  options: {
    retries: 0
  }
});

export const constellationData = ({ url, word }) => ({
  endpoint: url.replace(/[.\w+]*$/, `${word}.json`),
  options: {
    retries: 0
  }
});

export const saveNotes = (noteText, wordId, csrfToken) => ({
  endpoint: '/graphql.json',
  options: {
    ...basePostRequestOptions,
    headers: buildPostHeaders(csrfToken),
    body: JSON.stringify({
      text: noteText,
      customized_word_id: wordId,
      query: `mutation UpdateNote($noteAttributes: NoteAttributes!) {
        updateNote(attributes: $noteAttributes) {
          errors
          note {
            customizedWordId
                  id
            text
          }
        }
      }`,
      variables: {
        noteAttributes: {
          customizedWordId: wordId,
          text: noteText
        }
      }
    })
  }
});

// training requests
export const trainingPostBuilder = (
  reqBody,
  csrfToken,
  endpoint = '/training/process.json'
) => ({
  endpoint: endpoint,
  options: {
    ...basePostRequestOptions,
    headers: buildPostHeaders(csrfToken),
    body: JSON.stringify(reqBody)
  }
});

export const postRequestBuilder = (reqBody, csrfToken, endpoint) => ({
  endpoint: endpoint || window.location.pathname,
  options: {
    ...basePostRequestOptions,
    headers: buildPostHeaders(csrfToken),
    body: JSON.stringify(reqBody),
    timeout: 10000
  }
});

export const trainingPostRequestBodyBuilder = (dataInner, type) => ({
  training_gateway: {
    type: type,
    data: dataInner
  }
});

/* -- CALIBRATION REQUESTS -- */
export const calibrationInitRequest = {
  endpoint: '/calibration',
  options: {
    headers: buildPostHeaders('dsslkfsfkdslfjslfds'),
    timeout: 10000,
    ...basePostRequestOptions
  }
};

export const calibrationPostRequestBodyBuilder = (dataInner, type) => ({
  calibration: {
    type: type,
    data: dataInner
  }
});

/* -- VALIDATE REVISION REQUESTS -- */
/* 
  Check the latest deployed revision of the application
  IF the revision id is not matching with one what user has stored in browser
  then reload the application and update the revision id in browser storage.
*/

export const validateVersion = async () => {
  try {
    const res = await fetch('/heartbeat');
    const { revision } = await res.json();
    const oldRevision = localStorage.getItem(REVISION_ID);
    if (oldRevision) {
      if (oldRevision !== revision) {
        localStorage.setItem(REVISION_ID, revision);
        return true;
      }
    }
    localStorage.setItem(REVISION_ID, revision);
    return false;
  } catch (error) {
    throw new Error(error);
  }
};

/* -- LOG REFRESH ACTIVITY -- */
/* 
  Log's the activity of refreshed / refresh_prompt whenever new revision is available and browser refreshed.
*/

export const logActivity = ({ key, userId, masqueraderId }) => {
  return fetch('/graphql.json', {
    ...basePostRequestOptions,
    headers: buildPostHeaders('token'),
    body: JSON.stringify({
      query: `mutation AddActivity($activityAttributes: ActivityAttributes!) {
        addActivity(attributes: $activityAttributes) {
          activity {
            createdAt
            id
            key
            message
          }
          errors {
            key
            user
          }
        }
      }`,
      variables: {
        activityAttributes: {
          key: key,
          userId: userId,
          masqueradingUserId: masqueraderId
        }
      }
    })
  });
};
