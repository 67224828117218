import React from 'react';
import Alert from '../Alert';

function SentryErrorFallback() {
  return (
    <Alert
      alert={{
        title: 'We seem to be experiencing a problem.',
        description: `Please contact support@membean.com.`,
        postition: 'top',
        type: 'error'
      }}
    />
  );
}

export default SentryErrorFallback;
