import React from 'react';
import PropTypes from 'prop-types';
import sprite from 'framework/images/icons.svg';

export default function Icon(props) {
  const { className, hidden = true, name, interstitial = false } = props;
  const classes = interstitial
    ? 'icon-interstitial'
    : `icon icon-${name} ${className || ''}`;

  return (
    <svg
      aria-hidden={hidden}
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
    >
      <use href={`${sprite}#${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  interstitial: PropTypes.bool
};
