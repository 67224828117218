import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SkipLinks = forwardRef((props, ref) => {
  return (
    <ul className="skip-link-list" data-testid="skip-link-list">
      {!props.calibration && (
        <li className="skip-link-list-item">
          <a href="#content">Skip to content</a>
        </li>
      )}
      {!props.calibration && (
        <li className="skip-link-list-item">
          <a href="#footer">Skip to footer</a>
        </li>
      )}
      {(props.isTraining ||
        (props.type && props.type.toLowerCase() === 'question') ||
        (props.type && props.type.toLowerCase() === 'word') ||
        props.calibration) && (
        <li className="skip-link-list-item">
          <button
            ref={ref}
            data-testid="enable-shortcuts-button"
            onClick={() => props.showKeyboardShortcuts()}
          >
            Keyboard shortcuts
          </button>
        </li>
      )}
    </ul>
  );
});

export default SkipLinks;

SkipLinks.propTypes = {
  calibration: PropTypes.bool,
  isTraining: PropTypes.bool,
  showKeyboardShortcuts: PropTypes.func,
  type: PropTypes.string
};
