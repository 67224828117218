import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { REFRESH_POLLING_INTERVAL } from 'framework/constants';
import { validateVersion } from 'framework/api/requests';
import useInterval from 'framework/helpers/useInterval';
import { logActivity } from '../../api/requests';
import Alert from 'framework/components/Alert';

/**
 * We check every 15 minutes to see if there is new revision of the application is available
 * then refresh the browser automatically or else notify the user if there is new revision.
 *
 * Example usage:
 *
 * import CheckRevision from 'framework/components/CheckRevision';
 *
 * <CheckRevision autoRefresh={false} />
 *
 * We have autoRefresh default value as true
 * autoRefresh={false} should almost never be used. In this case if the encryption keys are out of sync between the browser and the server, it will cause a 500 error which will be worse for the user.
 * Automatically refreshing may cause a slight disruption, but it will be less intrusive than the alternative.
 * The only time we can allow a dismissible alert to show is on a page that does not submit any data and does a full hard refresh of the HTML in the browser that will force the new JS files to be loaded. This is a rare scenario.
 *
 * The CheckRevision flow is as follows:
 *
 * 1. We trigger the validateRevision function every hour to see if new revision is available or not.
 * 2. If we have the new revision we perform the operation based on the autorefresh as follows
 * CASE 1:
 *  If autoRefresh is enable then we automatically refresh the browser.
 * CASE 2:
 *  If autoRefresh is disable then we notify the user about the new update in alert with button to refresh browser manually.
 *
 * @param {Boolean} autoRefresh - The option opt-in and opt-out to do automatically browser refresh.
 * @param {number} userId - Logged in user (not masquerading)
 * @param {number} masqueraderId - Logged in user (masquerading)
 *
 */

const CheckRevision = props => {
  const { autoRefresh = true, userId = null, masqueraderId = null } = props;
  const [visible, setVisible] = useState(false);

  const onRefresh = async () => {
    if (userId) {
      await logActivity({
        key: visible ? 'refresh_prompted' : 'refreshed',
        userId,
        masqueraderId
      });
    }

    // hard reload the browser tab
    const url = new URL(document.location.href);
    document.location.assign(url.search);
  };

  const alert = {
    type: 'alert',
    title: 'We have an update available!',
    description: 'Please click "refresh" to get the latest changes.',
    dismissable: true,
    button: {
      text: 'Refresh',
      onClick: onRefresh
    }
  };

  useInterval(async () => {
    const res = await validateVersion();
    if (res) {
      if (autoRefresh) {
        onRefresh();
      } else {
        setVisible(true);
      }
    }
  }, REFRESH_POLLING_INTERVAL);

  if (autoRefresh) {
    return null;
  }

  return visible ? (
    <Alert
      alert={alert}
      key="refresh-alert"
      dismissCallback={() => setVisible(false)}
    />
  ) : null;
};

CheckRevision.propTypes = {
  autoRefresh: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  masqueraderId: PropTypes.number
};

export default CheckRevision;
