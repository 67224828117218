const omit = (obj, keysToOmit) => {
  let newObj = {};

  Object.keys(obj).filter(keyName => {
    if (Array.isArray(keysToOmit)) {
      if (!keysToOmit.includes(keyName)) {
        // if the current key is not in the list of keys to be omitted
        newObj[keyName] = obj[keyName];
      }
    } else {
      if (keyName !== keysToOmit) {
        newObj[keyName] = obj[keyName];
      }
    }
  });

  return newObj;
};

export default omit;
