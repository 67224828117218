import getCsrfFromDOM from './getCsrfFromDOM';

const buildPostHeaders = (token = '') => {
  const csrfToken = token ? token : getCsrfFromDOM();
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken,
    'X-Requested-With': 'XMLHttpRequest'
  };
};

export default buildPostHeaders;
