import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import SentryErrorFallback from './SentryErrorFallback';

/**
 *
 *  SentryWrapper > A higher order function used to wrapper around the pack to init sentry
 *  and setup errorBoundaries capture the react error.
 *
 * Example usage:
 *
 * import SentryWrapper from 'framework/components/SentryWrapper';
 *
 *  function App() {
 *     // wrap dashboard with packName as a dashboard.
 *     return  <SentryWrapper packName="dashboard" /> <Dashboard/> </SentryWrapper>
 *  }
 *
 * SentryWrapper flow is as follow:
 *    1. as soon as component mounts it will init new client with sentry.
 *    2. as soon as component numount it will close the client connection with sentry by 2ms
 *    3. wraps the children around the Sentry.ErrorBoundary to capture the react related errors.
 *    4. sentry has been configured to exclude the browser / extensions / google / console errors (unhandled errors).
 *   sentry only listens to the react error and custom captureException.
 *
 *
 * @param {String} packName - name of the packs where the sentry used.
 * @param {ReactElement} children - children for the Sentry Wrapper.
 * @returns {ReactElement}
 */

const SentryWrapper = ({ children, packName }) => {
  useEffect(() => {
    const sentryDsn = process.env.REACT_SENTRY_DSN; // eslint-disable-line

    if (sentryDsn) {
      Sentry.init({
        dsn: sentryDsn,
        environment: process.env.NODE_ENV, // eslint-disable-line
        tracesSampleRate: 1.0,
        initialScope: {
          tags: { package: packName },
          user: {
            id: window.__UID__,
            username: window.__USERNAME__
          }
        },
        integrations: [
          Sentry.globalHandlersIntegration({
            onunhandledrejection: false,
            onerror: false
          })
        ],
        ignoreErrors: [
          // random plugins/extentions
          'top.GLOBALS'
        ],
        denyUrls: [
          // Ignore Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Ignore Google flakiness
          /\/(gtm|ga|analytics)\.js/i
        ]
      });
    }

    return () => {
      // cleanup sentry or close sentry connection,
      Sentry.close(2000); /// passing timeout so the client should wait to flush its event queue before shutting down for 2ms.
    };
  }, []);

  return (
    <React.Fragment>
      <Sentry.ErrorBoundary fallback={<SentryErrorFallback />}>
        {children}
      </Sentry.ErrorBoundary>
    </React.Fragment>
  );
};

export default SentryWrapper;
